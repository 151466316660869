@tailwind base;
@tailwind components;
@tailwind utilities;

.fonts {
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.canvas-container {
  margin: 0 auto;
}
.upper-canvas {
  border: 5px solid white;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
/* Prevent selecting items */
.canvas-container:after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
}
